#login-card{
    border: 20px solid transparent;
    border-image-width: 50px; 
    padding: 5px;
    border-image-source: url("/LudomingaUIAssets/LogInAssets/border.png");
    border-image-slice: 100; 
}
#button{
    border-radius: 15px;
    background-color: #83609e;
    border-width: 20px;
    border: 2px solid #ffffff;
    color: #00fcf3 ;
    margin:auto; 
    font-family: "rounded";
}
#buttonWhite{
    font-family: "rounded";
    border-radius: 15px;
    background-color: #83609e;
    border-width: 20px;
    border: 2px solid #ffffff;
    color:  #ffffff;
    margin:auto; 
}
#buttonWhite:hover{

    border: 2px solid #12b6df;
    color:  #00fcf3 ;;
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
}
#button:hover{
    border: 2px solid #12b6df;
    color:  #ffffff ;;
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
}
#form{
    width: 20%;
    text-align: "left"
}
#h1Font{
    font-family: "robot";
}
.form{
    text-align: "left";
    }

@font-face {
    font-family: "rounded";
    src: url("/LudomingaUIAssets/Font/ArialRoundedMT.ttf");
}