.App {
  background-image: url("/LudomingaUIAssets/commonAssets/Background.png");
  background-size: cover;
  background-position: center;
  min-width: 100vw;

}
.page-container{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrap{
  flex: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.menuButton:hover {
  background-image: url("/LudomingaUIAssets/commonAssets/layer2.png");
  background-position: center;
  background-size: cover;
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 
}
.cardHover:hover {
  background-image: url("/LudomingaUIAssets/commonAssets/layout3.png");
  background-position: center;
  background-size: cover;
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05); 

}
.backButton:hover {
  
  background-image: url("/LudomingaUIAssets/commonAssets/ligthBackButton.png");
  background-position: center;
  background-size: cover;
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05); 
}
.chevron:hover{
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2); 
}
@font-face {
  font-family: "rounded";
  src: url("/LudomingaUIAssets/Font/ArialRoundedMT.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  }